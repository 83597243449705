/*-----------------------------------*/
/*--- IMPORT STYLES FOR FASTPAGES ---*/
@import "minima/fastpages-styles";   



/*-----------------------------------*/
/*----- ADD YOUR STYLES BELOW -------*/

// If you want to turn off dark background for syntax highlighting, comment or delete the below line.
@import "minima/fastpages-dracula-highlight"; 


// make body text (paragraphy) smaller (was 20px)
.post-content p, .post-content li {
   font-size: 16px;
}  

// MORGAN: Change Post-Title (post-header) size
// Defaults from minima/_sass/minima/_layout.scss were relative-font-size(2.625) and relative-font-size(2.625)
.post-header h1 {
  @include relative-font-size(2.0);  // 2.0

  @media screen and (min-width: $on-large) {
    @include relative-font-size(2.25);  // 2.25
  }
}

.post-header p {
  font-style: italic
}

// MORGAN: Make heading sizes smaller in body
.post-content {
  h1 {
    @include relative-font-size(1.75);

    @media screen and (min-width: $on-large) {
      @include relative-font-size(2);
    }
  }
  h2 {
    @include relative-font-size(1.375);

    @media screen and (min-width: $on-large) {
      @include relative-font-size(1.625);
    }
  }
  h3 {
    @include relative-font-size(1.25);
  }
  h4 {
    @include relative-font-size(1.125);
  }
  h5 {
    @include relative-font-size(1.0625);
  }
  h6 {
    @include relative-font-size(1.0625);
  }
}

// width of the content area
// can be set as "px" or "%"
$content-width:    1100px;
$on-palm:          800px;
$on-laptop:        1100px;
$on-medium:        1100px;
$on-large:         1200px;


// .page {
//   width: calc(100% - #{$right-sidebar-width-narrow});
// }

// Edits the navigation buttons to white, monospace text until hover over
// @media screen and (min-width: 600px) {
//   // .site-nav .page-link {
//   //   color: rgb(11, 255, 52);
//   //   font-size: 14pt;
//   //   border-top: 5pt solid #54008c;
//   //   border-bottom: 5pt solid #54008c;
//   // }

//   // Navgation headings
//   .site-nav .page-link {
//     color: #fff;
//     font-size: 14pt;
//     border-top: 5pt solid #54008c;
//     border-bottom: 5pt solid #54008c;
//     font-family: "Source Code Pro", "Menlo", "DejaVu Sans Mono", "Lucida Console", monospace;
//   }

//   .site-nav .page-link:hover {
//     color: #000000;
//     background-color: #fff;
//     border-color: #fff;
//     text-decoration: none;
//   }
// }
